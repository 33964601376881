import { Token } from '../models/Invitation';
import Request, { CardType, RequestStatus } from '../models/Request';
import instance from './instance';

function requestCard(quantity: number, cardType: CardType, userId?: string): Promise<string> {
    return instance.post(`user/request-card`, { quantity, type: cardType, userId }).then((response) => {
        return response.data;
    });
}

function getRequestsForUser(userId?: string): Promise<Request[]> {
    return instance.get(`request?userId=${userId}`).then((response) => {
        return response.data;
    });
}

function getAllRequests(): Promise<Request[]> {
    return instance.get(`requests`).then((response) => {
        return response.data;
    });
}

function changeCardStatus(status: RequestStatus, id: string): Promise<string> {
    return instance.put(`request/card-status`, { id, status }).then((response) => {
        return response.data;
    });
}

function getTokensForRequest(id: string): Promise<Token[]> {
    return instance.get(`request/${id}/tokens`).then((response) => {
        return response.data;
    });
}

export { requestCard, getRequestsForUser, getAllRequests, changeCardStatus, getTokensForRequest };
